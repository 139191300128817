/* THEME */

$light-theme: true;

/* COLOURS */

$primary-color: #d34d2a;
$secondary-color: #d34d2a;
$tertiary-color: #7e8588;
$quaternary-color: #b79257;
$primary-alternate: #fff;
$primary-dark-color: #231f20;
$secondary-dark-color: #313131;

$success-color: #198754;
$error-color: #dc3545;
$warning-color: #ff854f;
$info-color: #707070;
$grey-color: #999999;
$muted-color: #f7f7f7;
$disabled-color: #dedddd;
$payment-color: #04955f;
$white: #ffffff;
$black: #000000;
$dark: #212529;
$light: #f8f9fa;
$black-2: #1f1f1f;
$black-3: #111111;

$auction-color: #555f66;
$marketplace-color: $secondary-color;
$lotto-color: $quaternary-color;
$wallet-color: $primary-color;
$points-color: $primary-color;
$ecash-color: $primary-color;
$events-color: $quaternary-color;
$predictor-color: $primary-color;
$poll-color: $primary-color;
$quiz-color: $black;

$marketplace-0-color: $primary-color; // FortressGB
$marketplace-1-color: $primary-color; // Unique Code
$marketplace-2-color: $primary-color; // Download
$marketplace-3-color: $events-color; // Event
$marketplace-4-color: $tertiary-color; // Product
$wallet-color: $secondary-color;
$wallet-redeemed-color: $muted-color;

$navbar-color: $black;
$navbar-link-color: white;
$navbar-progress-color: $secondary-color;
$navbar-secondary-color: $black;

/* FONTS */

@font-face {
  font-family: 'AccuminPro';
  src: url('../app/fonts/accumin/accumin_bold.otf') format('opentype');
}

$font-default: 'Open Sans', Arial, sans-serif;
$font-heading: 'AccuminPro', Arial, sans-serif;
$font-sans-bold: 'Open Sans Bold', Arial, sans-serif;

/* GENERAL */

$navbar-height: 54px;
$navbar-mobile-height: 50px;
$footer-height: 180px;
$footer-mobile-height: 180px;
$panel-height: 150px;
$cta-height: 50px;
$main-padding-bottom: 40px;
$header-font-size: 20px;

$loyalty-image-height: 390px;

$border-radius: 16px;

$card-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.15);
$nav-secondary-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);

$read-more-overlay-enabled: false;

/* BOOTSTRAP OVERRIDES */

$theme-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'tertiary': $tertiary-color,
  'quaternary': $quaternary-color,
  'primary-alternate': $primary-alternate,
  'muted': $muted-color,
  'disabled': $disabled-color,
  'info': $info-color,
  'grey': $grey-color,
  'success': $success-color,
  'danger': $error-color,
  'auction': $auction-color,
  'marketplace': $marketplace-color,
  'mp-unique-code': $secondary-color,
  'mp-download': $secondary-color,
  'mp-event': $events-color,
  'mp-virtual-event': $events-color,
  'mp-product': $marketplace-color,
  'lotto': $lotto-color,
  'wallet': $wallet-color,
  'points': $points-color,
  'ecash': $ecash-color,
  'predictor': $predictor-color,
  'poll': $poll-color,
  'quiz': $quiz-color,
  'affiliate': $primary-color,
  'transfer': $primary-color,
  'upgrade-child': #7e8588,
  'white': #ffffff,
  'black': #000000,
  'sold-out': #70707033,
  'coming-soon': #70707033,
  'item-locked': #70707033,
  'limit-reached': #e03e3e33,
  'not-enough-points': #e03e3e33,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
);

$sizes: (
  90: 90%,
);

$grid-gutter-width: 22px;

$enable-rounded: true;
$enable-validation-icons: false;

$border-radius-base: 4px;

$box-shadow-sm: 0 0 0.25rem rgba(#000, 0.15);
$box-shadow: 0 0.1rem 0.5rem rgba(#000, 0.15);

/* Scorecards */
$scorecard-footer-background-color: $black;
$scorecard-prize-label-size: 0.875rem;
$scorecard-name-color: $secondary-color;
$scorecard-name-weight: normal;
$scorecard-steps-color: $black;
$badge-subheading-color: $black;
$scorecard-punchcard-steps-size: 12px;
$scorecard-milestones-prize-label-color: $primary-color;

/* Rewards */
$featured-rewards-show-all-color: $white;
$marketplace-purchase-button-background-color: $secondary-color;
$marketplace-purchase-button-border-color: $secondary-color;
$lotto-details-button-background-color: $secondary-color;
$lotto-details-button-border-color: $secondary-color;
$countdown-timer-color: $black;
$marketplace-confirmation-button-cancel-background-color: $black;
$marketplace-confirmation-button-confirm-background-color: $secondary-color;
$marketplace-attributes-selected-background-color: $secondary-color;
$marketplace-selected-attributes-button-background-color: $secondary-color;
$marketplace-selected-attributes-button-border-color: $secondary-color;

/* Payment */
$payment-add-card-button-color: $primary-color;
$payment-add-card-button-color: $primary-color;
$payment-card-front-active-bg: $primary-color;
$payment-card-front-active-border: $primary-color;

/* Announcements */
$announcements-title-font-size: 16px;

/* Marketplace */
$short-long-btn-color: $black;
$auction-detail-description-title-color: $black;

$faq-header-background-color: $quaternary-color;
$registration-terms-and-condition-title-color: $black;
$registration-terms-and-condition-title-font-size: 22px;
$registration-personal-details-title-color: $black;
$registration-personal-details-title-font-size: 22px;
$registration-personal-details-logo-mobile-display: none;

$registration-partner-invite-title-font-size: 22px;
$registration-partner-invite-title-margin-bottom: 20px;
$registration-partner-invite-title-color: $black;
$registration-partner-invite-instruction-font-size: 12px;
$registration-partner-invite-instruction-margin-bottom: 20px;
$registration-partner-invite-title-mobile-text-align: left;
$registration-questions-title-color: $black;
$registration-questions-title-font-size: 22px;
$registration-questions-title-mobile-text-align: left;
$registration-questions-title-mobile-margin-top: 0;
$registration-provider-oauth-title-font-size: 22px;
$registration-questions-title-font-size: 22px;
$registration-add-children-title-color: $black;
$registration-add-children-title-font-size: 22px;
$registration-success-button-font-size: 14px;
$registration-success-button-font-weight: bold;

$model-meta-data-form-error-message-margin-top: 5px;
$registration-add-children-mobile-image-logo-width: auto;
$registration-membership-options-mobile-logo-image-width: auto;
$registration-payment-layout-mobile-logo-width: auto;
