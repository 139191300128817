@import 'partials';

// @TODO: need to create separate mixin for each component styling rather than using a single mixin
@mixin set-theme($theme, $variables) {
  @if (
    map-has-key($theme, 'name') and
      map-has-key($theme, 'primary') and
      map-has-key($theme, 'secondary') and
      map-has-key($theme, 'tertiary') and
      map-has-key($theme, 'accent')
  ) {
    $theme-name: map-get($theme, 'name');
    $primary: map-get($theme, 'primary');
    $secondary: map-get($theme, 'secondary');
    $tertiary: map-get($theme, 'tertiary');
    $accent: map-get($theme, 'accent');

    .#{$theme-name}-theme {
      $navbar-background-color: get-value($primary-dark-color, $variables, 'navbar', 'background-color');
      $navbar-logo: get-value(url(~src/assets/images/logos/Ducks.svg), $variables, 'navbar', 'logo');
      $navbar-logo-height: get-value(47px, $variables, 'navbar', 'logo-height');
      $navbar-logo-width: get-value(49px, $variables, 'navbar', 'logo-width');
      $nav-bar-logo-transform: get-value(scale(1), $variables, 'navbar', 'logo-transform');
      $nav-secondary-background-color: get-value($tertiary, $variables, 'nav-secondary', 'background-color');
      $banner-background-desktop: get-value(
        url(~src/assets/images/banners/orange-alliance-banner-desktop.png),
        $variables,
        'banner',
        'background-desktop'
      );
      $banner-background-mobile: get-value(
        url(~src/assets/images/banners/orange-alliance-banner-mobile.png),
        $variables,
        'banner',
        'background-mobile'
      );
      $footer-background-color: get-value($primary-dark-color, $variables, 'footer', 'background-color');
      $background-color: get-value($secondary, $variables, 'background-color');
      $barcode-content-background-color: get-value(#111111, $variables, 'barcode', 'content-background-color');
      $voucher-background-color: get-value($tertiary, $variables, 'voucher', 'background-color');
      $voucher-title-color: get-color-by-background(
        $background-color,
        #ffffff,
        get-value($accent, $variables, 'voucher', 'title-color')
      );
      $details-back-button-text-color: get-value($error-color, $variables, 'details', 'back-button-text-color');
      $label-text-color: get-value($black, $variables, 'details', 'label-text-color');
      $label-font-size: get-value(20px, $variables, 'details', 'label-font-size');
      $partner-accept-button-background-color: get-value($accent, $variables, 'partner', 'accept-button-background-color');
      $scorecard-background-color: get-value($tertiary, $variables, 'scorecards', 'background-color');
      $scorecard-item-background-color: get-value($tertiary, $variables, 'scorecards', 'item-background-color');
      $scorecard-item-title-color: get-value($primary, $variables, 'scorecards', 'item-title-color');
      $socrecard-prize-background-color: get-value($primary, $variables, 'scorecards', 'prize-background-color');
      $scorecard-prize-label-color: get-value($accent, $variables, 'scorecards', 'prize-label-color');
      $scorecard-text-color: get-value(#000000, $variables, 'scorecards', 'text-color');
      $scorecard-locked-border: get-value(1px solid #dedddd, $variables, 'scorecards', 'locked-border');
      $game-nav-order: get-value(unset, $variables, 'game-nav', 'order');
      $game-nav-mobile-order: get-value(unset, $variables, 'game-nav', 'mobile-order');
      $badge-background-color: get-value($tertiary, $variables, 'badges', 'background-color');
      $announcement-background-color: get-value($tertiary, $variables, 'announcements', 'background-color');
      $survey-quiz-header-background-color: get-value($accent, $variables, 'surveys', 'quiz-header-background-color');
      $survey-quiz-info-background-color: get-value($black, $variables, 'surveys', 'quiz-info-background-color');
      $survey-quiz-background-color: get-value($tertiary, $variables, 'surveys', 'quiz-background-color');
      $survey-poll-background-color: get-value($primary, $variables, 'surveys', 'poll-background-color');
      $survey-instruction-font-size: get-value(14px, $variables, 'surveys', 'instruction-font-size');
      $survey-answer-text-background-color: get-value($background-color, $variables, 'surveys', 'answer-text-background-color');
      $survey-poll-answer-text-background-color: get-value($tertiary, $variables, 'surveys', 'poll-answer-text-background-color');
      $survey-predictor-background-color: get-value($primary, $variables, 'surveys', 'predictor-background-color');
      $survey-predictor-asnwer-text-background-color: get-value(
        $survey-answer-text-background-color,
        $variables,
        'surveys',
        'predictor-asnwer-text-background-color'
      );
      $survey-predictor-header-background-color: get-value(
        $primary-color,
        $variables,
        'surveys',
        'predictor-header-background-color'
      );
      $survey-predictor-answer-text-background-color: get-value(
        $survey-answer-text-background-color,
        $variables,
        'surveys',
        'predictor-answer-text-background-color'
      );
      $member-card-background-color: get-value($primary-dark-color, $variables, 'member-card', 'background-color');
      $card-background-color: get-value($tertiary, $variables, 'card', 'background-color');
      $one-punch-incomplete-background-color: get-value(
        #dedddd,
        $variables,
        'scorecards',
        'one-punch-incomplete-background-color'
      );
      $carousel-dot-color: get-value($primary-dark-color, $variables, 'carousel', 'dot-color');
      $modal-background-color: get-value($card-background-color, $variables, 'modal', 'background-color');
      $no-child-border-color: get-value(#e3e3e3, $variables, 'child-list', 'no-child-border-color');
      $no-child-background-color: get-value(#ffffff, $variables, 'child-list', 'no-child-background-color');
      $border: get-value(1px solid $disabled-color, $variables, 'border');
      $reward-attribute-background-color: get-value($tertiary, $variables, 'reward', 'attribute-background-color');
      $wallet-redeemed-background-color: get-value($background-color, $variables, 'wallet-redeemed', 'background-color');
      $wallet-redeemed-border-bottom-color: get-value($border, $variables, 'wallet-redeemed', 'border-bottom-color');
      $child-upgrade-btn-background-color: get-value(#231f20, $variables, 'child-list', 'upgrade-btn-background-color');
      $toast-background-color: get-value($background-color, $variables, 'toast', 'background-color');

      $text-color: get-color-by-background($card-background-color);
      $input-background-color: get-value(
        get-color-by-background($background-color, $white, $white),
        $variables,
        'input',
        'background-color'
      );
      $input-disabled-background-color: get-value(
        get-color-by-background($background-color, $white, $muted-color),
        $variables,
        'input',
        'disabled-background-color'
      );
      $input-border-color: rgba(0, 0, 0, 0.125);
      $divider-color: rgba(0, 0, 0, 0.125);
      $voucher-input-background-color: get-value(#dedddd, $variables, 'voucher', 'input-background-color');
      $child-list-loading-color: get-value(get-color-by-background($background-color), $variables, 'child-list', 'loading-color');
      $reward-points-display: get-value(flex, $variables, 'reward', 'points-display');
      $reward-purchase-button-disabled-background-color: get-value(
        $primary-color,
        $variables,
        'reward',
        'purchase-button-disabled-background-color'
      );
      $reward-purchase-button-disabled-text-color: get-value($white, $variables, 'reward', 'purchase-button-disabled-text-color');
      $reward-purchase-button-disabled-opacity: get-value($white, $variables, 'reward', 'purchase-button-disabled-opacity');
      $notification-vertical-separator-display: get-value(block, $variables, 'notification', 'vertical-separator-display');
      $modal-border-color: get-value($disabled-color, $variables, 'modal', 'border-color');
      $scorecard-navigation-background-color: get-value($white, $variables, 'scorecards', 'navigation-background-color');
      $arrow-black-color: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
      $arrow-white-color: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");

      @if (lightness(invert($text-color)) < 50%) {
        $input-border-color: transparent;
      }

      @if (lightness($background-color) < 50%) {
        $divider-color: #ffffff;
      }

      /* Global Styling */

      .border,
      .scorecard,
      .badge-container {
        border: get-color-by-background($background-color, $border, 1px solid #dedddd) !important;
      }

      .form-floating {
        label {
          color: $text-color !important;
        }

        label::after {
          background-color: unset !important;
        }
      }

      .form-control:focus {
        border-color: get-color-by-background($background-color, $white, $disabled-color);
        box-shadow: none;
      }

      .form-select:not(.disappear-appearance) {
        $form-select-black-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        $form-select-white-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        background-image: get-color-by-background(
          $background-color,
          $form-select-white-bg-img,
          $form-select-black-bg-img
        ) !important;
      }

      display: block;
      background-color: $background-color;

      h2,
      h5 {
        font-size: 20px;
        color: $text-color;
        text-transform: uppercase;
      }

      hr {
        border-color: $divider-color;
      }

      .card {
        color: get-color-by-background($card-background-color) !important;
        background-color: $card-background-color !important;
      }

      #navbar {
        background-color: $navbar-background-color;
      }

      .mobile-nav-menu,
      #navbar {
        #brand-logo {
          background-repeat: no-repeat;
          background-position: center;
          background-image: $navbar-logo;
          transform: $nav-bar-logo-transform;
        }
      }

      #navbar {
        #brand-logo {
          height: $navbar-logo-height;
          width: $navbar-logo-width;
        }

        .navbar-brand {
          height: $navbar-logo-height;
        }

        .nav-more .dropdown {
          .dropdown-menu {
            margin-top: 20px;
            min-width: 215px;
            background-color: $card-background-color;
            left: -70px !important;

            * {
              color: get-color-by-background($card-background-color) !important;
            }
          }
        }
      }

      @if (map-has-key($variables, 'dropdown', 'border-color')) {
        $dropdown-border-color: map-get($variables, 'dropdown', 'border-color');

        .dropdown-menu,
        .notification-dropdown {
          border-color: $dropdown-border-color;
        }
      } @else {
        .dropdown-menu,
        .notification-dropdown {
          border-color: get-color-by-background($background-color, $disabled-color, $disabled-color);
        }
      }

      #account-dropdown {
        padding: 0 10px !important;
        margin-top: 30px !important;

        @if (map-has-key($variables, 'navbar', 'account-dropdown-menu-background-color')) {
          $navbar-account-dropdown-menu-background-color: map-get($variables, 'navbar', 'account-dropdown-menu-background-color');
          background-color: $navbar-account-dropdown-menu-background-color !important;

          *:not(.logout-btn *) {
            color: get-color-by-background($navbar-account-dropdown-menu-background-color) !important;
          }
        } @else {
          background-color: $card-background-color !important;

          *:not(.logout-btn *) {
            color: get-color-by-background($card-background-color) !important;
          }

          *.logout-btn * {
            color: get-color-by-background($card-background-color, $white, $error-color) !important;
          }
        }

        .dropdown-item {
          &.account-btn {
            height: 55px;
          }

          &:hover {
            background-color: transparent;
          }

          .dropdown-link .logout-btn {
            padding-left: 0 !important;
          }
        }

        @if (map-has-key($variables, 'navbar', 'account-menu-divider-color')) {
          hr {
            border-color: map-get($variables, 'navbar', 'account-menu-divider-color');
          }
        }
      }

      .mobile-nav-menu {
        #brand-logo {
          @if (
            map-has-key($variables, 'navbar', 'logo-height') and
              map-has-key($variables, 'navbar', 'logo-width') and
              map-has-key($variables, 'navbar', 'background-size')
          ) {
            height: map-get($variables, 'navbar', 'logo-height');
            width: map-get($variables, 'navbar', 'logo-width');
            background-size: map-get($variables, 'navbar', 'background-size');
            transform: scale(1.3);
          } @else {
            height: $navbar-logo-height;
            width: $navbar-logo-width;
          }
        }
      }

      .mobile-nav-menu-dropdown {
        @if (map-has-key($variables, 'navbar', 'logo-height')) {
          .mobile-nav-header {
            margin-top: 10px !important;
          }
        }
      }

      .nav.nav-secondary {
        background-color: $nav-secondary-background-color;

        .nav-item {
          background-color: $nav-secondary-background-color;

          label,
          .icon {
            color: get-color-by-background($nav-secondary-background-color, #ffffff, $info-color);
          }
        }
      }

      .game-nav-wrapper {
        @if (map-has-key($variables, 'game-nav', 'mobile-margin-top')) {
          margin-top: map-get($variables, 'game-nav', 'mobile-margin-top') !important;
        }

        order: $game-nav-order;
      }

      /* Home Page */

      .announcement-container {
        @if (map-has-key($variables, 'home', 'announcement-order')) {
          order: map-get($variables, 'home', 'announcement-order');
        }
      }

      .challenges-container {
        @if (map-has-key($variables, 'home', 'challenges-order')) {
          order: map-get($variables, 'home', 'challenges-order');
        }
      }

      .rewards-container {
        @if (map-has-key($variables, 'home', 'marketplace-order')) {
          order: map-get($variables, 'home', 'marketplace-order');
        }
      }

      .featured-games-container {
        @if (map-has-key($variables, 'home', 'featured-games-order')) {
          order: map-get($variables, 'home', 'featured-games-order');
        }
      }

      .video-container {
        @if (map-has-key($variables, 'home', 'video-order')) {
          order: map-get($variables, 'home', 'video-order');
        }
      }

      .banner {
        justify-content: center;
        background-image: $banner-background-desktop;
        background-size: auto 100px;
        background-repeat: repeat;
      }

      @if (map-has-key($variables, 'barcode', 'container-margin-bottom')) {
        .barcode-container {
          margin-bottom: map-get($variables, 'barcode', 'container-margin-bottom') !important;
        }
      }

      fgb-barcode {
        .spinner-border {
          color: $black !important;
        }
      }

      .barcode-content-container {
        background-color: $barcode-content-background-color;

        @if (map-has-key($variables, 'barcode', 'content-padding-top')) {
          padding-top: map-get($variables, 'barcode', 'content-padding-top') !important;
        }
      }

      .no-results-placeholder-div {
        border-color: get-color-by-background($background-color, $white, $disabled-color) !important;
        color: get-color-by-background($background-color) !important;
      }

      /* Voucher */

      .voucher-box {
        .error.empty {
          @if (map-has-key($variables, 'voucher', 'error-empty-color')) {
            color: map-get($variables, 'voucher', 'error-empty-color');
          } @else {
            color: $white;
          }
        }
      }

      fgb-vouchers {
        fgbcl-errors {
          .alert {
            margin-top: 0;
          }
        }
      }

      fgb-vouchers {
        .alert-danger {
          font-size: 12px;
        }
      }

      @if (map-has-key($variables, 'mobile-pass', 'background-color')) {
        .apple-wallet-enabled .barcode-content-container {
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        .mobile-pass.bg-mobile-pass {
          background-color: map-get($variables, 'mobile-pass', 'background-color');
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }

      /* Worksheet */

      .worksheet-item {
        &.download {
          .download-btn {
            background-color: $primary-color;
          }
        }
      }

      /* Menu Navbar */

      .mobile-nav-menu {
        .card-name {
          color: $white !important;
        }

        .barcode-content {
          padding-bottom: 0 !important;
        }

        @if (map-has-key($variables, 'mobile-nav', 'logo-height')) {
          .logo {
            width: auto;
            height: map-get($variables, 'mobile-nav', 'logo-height');
          }
        }
      }

      /* Carousel */

      .owl-theme .owl-dots .owl-dot.active span,
      .owl-theme .owl-dots .owl-dot:hover span {
        background-color: $carousel-dot-color !important;
      }

      /* Footer */

      .footer-text {
        background-color: $footer-background-color;
      }

      /* Scorecards */

      fgbcl-scorecard-list-view {
        .bg-carousel {
          padding-top: 15px;
        }
      }

      .scorecard-description-modal-carousel {
        background-color: $scorecard-navigation-background-color;

        .previous,
        .steps,
        .current-step,
        .dash,
        .total-step,
        .next {
          color: get-color-by-background($scorecard-navigation-background-color) !important;
        }
      }

      .scorecard-locked-header,
      .scorecard-locked-footer {
        border: $scorecard-locked-border;
      }

      .badge-carousel-subheading {
        color: get-color-by-background($scorecard-background-color);
      }

      .bg-carousel {
        background-color: inherit !important;
      }

      .scorecard-container,
      .scorecard {
        background-color: $scorecard-item-background-color;
      }

      .scorecard-punchcard-footer-container {
        border-color: $accent;
      }

      .scorecard-description-modal-prizebox {
        background-color: $socrecard-prize-background-color !important;
        border: 1px solid $primary-color !important;
      }

      .scorecard-name {
        color: $text-color;
      }

      .scorecard-description-modal .scorecard-actions-completed {
        background-color: get-color-by-background($background-color, $secondary, $primary-dark-color) !important;
        text-transform: uppercase;
      }

      .scorecard-milestones-prize-label,
      .scorecard-punchcard-prize-label,
      .scorecard-description-modal-prize-v2,
      .scorecard-description-modal-congratulation-title,
      .rewards-item-panel-name,
      .featured-rewards-text,
      .featured-rewards-show-all .material-icons,
      .punchcard-info-btn,
      .milestone-info-btn,
      .scorecard-carousel-links,
      .scorecard-locked-description,
      .featured-events-text span,
      .rewards-terms-unchecked {
        color: get-color-by-background($background-color) !important;
      }

      .wallet-redeemed-title {
        color: get-color-by-background($background-color, $white, $info-color) !important;
      }

      .reward-back-btn,
      .loyalty-item-container .item-count,
      .rewards-item-panel-status .item-count,
      .marketplace-wallet-expiry {
        color: get-color-by-background($background-color, #ffffff, $error-color) !important;
      }

      .marketplace-wallet-download-btn {
        &.my-3 {
          margin: 0 !important;
        }
      }

      fgb-wallet-claimed-page {
        .border:not(.redeemed-border) {
          border-radius: $border-radius;
        }
      }

      :has(.marketplace-wallet-download-btn) {
        .wallet-info-box {
          min-height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .rewards-item-panel-status {
        .text-success,
        &.text-rewards-success .item-state,
        &.text-rewards-new .item-state {
          color: get-color-by-background($background-color, #ffffff, $success-color) !important;
        }
        &.status-text-on-not-enough-points,
        &.status-text-on-limit-reached,
        &.text-rewards-danger .item-state {
          color: get-color-by-background($background-color, #ffffff, $error-color) !important;
        }
        &.status-text-on-sold-out,
        &.status-text-on-coming-soon {
          color: get-color-by-background($background-color, #ffffff, $info-color) !important;
        }
      }

      .rewards-terms-checked {
        color: get-color-by-background($background-color, #ffffff, $success-color) !important;
      }

      input[type='checkbox']:checked {
        $checkbox-white-color: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
        $checkbox-black-color: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
        background-image: get-color-by-background($background-color, $checkbox-black-color, $checkbox-white-color) !important;
        border-color: get-color-by-background($background-color, $white, $success-color) !important;
        background-color: get-color-by-background($background-color, $white, $success-color) !important;
      }

      input[type='checkbox'] {
        background-color: inherit !important;
        border-color: get-color-by-background($background-color) !important;
      }

      .number-input-increment-button:not(:disabled),
      .number-input-decrement-button:not(:disabled),
      .number-input-increment-button:not(:disabled):active,
      .number-input-decrement-button:not(:disabled):active {
        background: get-color-by-background($background-color, $secondary, #ffffff) !important;
        color: $text-color;
      }

      .number-input-increment-button:disabled,
      .number-input-decrement-button:disabled {
        background: get-color-by-background($background-color, #f7f7f7, #ffffff) !important;
        color: get-color-by-background($background-color, #dedddd, $black);
      }

      .rewards-item-panel-value {
        color: get-color-by-background($background-color, #ffffff, $primary-color) !important;
        display: $reward-points-display;
      }

      fgbcl-marketplace-purchase-confirmation {
        #points-value {
          display: block !important;
        }
      }

      @if $reward-points-display == none {
        fgbcl-marketplace-details {
          // check if it has reward status then hide it
          :has(.bg-not-enough-points) {
            .status-container {
              display: none;
            }
          }
        }

        fgb-points-summary {
          display: none;
        }

        .wallet-divider {
          display: none;
        }

        fgbcl-marketplace-purchase-confirmation {
          #points-value {
            display: none !important;
          }
        }

        #points-value {
          display: none !important;
        }
      }

      fgb-points-summary-bar {
        .points-summary-bar .text-danger {
          color: get-color-by-background($background-color, #ffffff, $error-color) !important;
        }
      }

      .scorecard-description-modal-congratulation-subtitle {
        font-size: 12px;
      }

      .scorecard-description-modal-carousel .dash {
        background: $black;
      }

      .scorecard-punchcard-prize-text,
      .scorecard-description,
      .scorecard-label-ellipsis {
        color: $scorecard-text-color !important;
      }

      .scorecard-punchcard-steps {
        font-family: var(--font-default);
        font-weight: bold;
      }

      .scorecard-milestones-prize-text,
      .scorecard-punchcard-steps {
        color: get-color-by-background($scorecard-item-background-color);
      }

      .scorecard-prize-border {
        background-color: $socrecard-prize-background-color;
      }

      .scorecard-image-one-punch .img-container {
        background-color: transparent !important;
      }

      #scorecard-description,
      .scorecard-milestone-value {
        color: $text-color;
      }

      .scorecard-description-modal-prize-description {
        color: get-color-by-background($scorecard-item-background-color);
      }

      .modal-header {
        border-bottom: none;
      }

      .scorecard-description-modal {
        background-color: $scorecard-item-background-color;
        color: get-color-by-background($scorecard-item-background-color);
      }

      .scorecard-modal-title {
        color: get-color-by-background($scorecard-item-background-color);
      }

      .scorecard-description-modal .modal-close-button,
      .scorecard-description-modal-prize-v2 {
        color: get-color-by-background($scorecard-item-background-color);
      }

      /* Member card */
      .member-card {
        background-color: $member-card-background-color;

        @if (map-has-key($variables, 'member-card', 'show-border')) {
          $member-card-border: map-get($variables, 'member-card', 'show-border');

          @if ($member-card-border == false) {
            hr {
              display: none;
            }
          } @else {
            hr {
              display: inherit;
            }
          }
        }
      }

      /* Child */

      .child-list {
        .no-child-border {
          border-color: $no-child-border-color;
          background-color: $no-child-background-color;
        }
      }

      .kids-added-card {
        .action-wrapper {
          .btn-upgrade-child {
            $child-upgrade-bg-color: get-color-by-background($background-color, #ffffff, $child-upgrade-btn-background-color);
            background-color: $child-upgrade-bg-color;
            color: get-color-by-background($child-upgrade-bg-color);

            .text-white {
              color: get-color-by-background($child-upgrade-bg-color) !important;
            }
          }
        }
      }

      fgb-child-list {
        .loading-container {
          .spinner-border {
            color: $child-list-loading-color;
          }
        }
      }

      fgb-child-panel {
        .action-panel {
          .child-upgrade-btn {
            $child-upgrade-bg-color: get-color-by-background($background-color, #ffffff, $child-upgrade-btn-background-color);
            background-color: $child-upgrade-bg-color;
            color: get-color-by-background($child-upgrade-bg-color);
          }
        }
      }

      /* Modal */
      .confirm-modal,
      .guardian-modal .modal-content {
        color: get-color-by-background($modal-background-color);
        background-color: $modal-background-color;
      }

      .guardian-modal {
        .action-wrapper {
          .btn-danger {
            color: get-color-by-background($modal-background-color, $white, $error-color) !important;
          }
        }
      }

      .guardian-modal-logo {
        background-image: url(~src/assets/images/logo_kids.svg);
      }

      .modal-dialog {
        .modal-content {
          border-color: get-color-by-background($modal-background-color, $info-color, #dedddd);
        }
        .scorecard-description-modal {
          border: 1px solid get-color-by-background($modal-background-color, $info-color, #dedddd);
        }
        > * {
          background-color: $modal-background-color;
          color: get-color-by-background($modal-background-color);
        }

        .purchase-confirmation-name,
        .confirmation-attribute-value,
        .purchase-confirmation-title,
        .confirmation-custom-field-value,
        .quantity-value,
        .scorecard-description-modal-desc-terms .accordion-button,
        .short-long-description-text,
        .btn.btn-toggle,
        .scorecard-item-terms-text {
          color: get-color-by-background($modal-background-color) !important;
        }

        .modal-header {
          border-bottom-color: get-color-by-background($modal-background-color, $modal-border-color, $disabled-color) !important;
        }

        .modal-footer {
          border-top-color: get-color-by-background($modal-background-color, $modal-border-color, $disabled-color) !important;
        }

        .btn.text-danger,
        .btn-purchase-cancel {
          color: get-color-by-background($modal-background-color, $white, $error-color) !important;
        }
      }

      /* Kids */

      fgb-guardian-page {
        .description {
          h5,
          h5.text-black {
            color: get-color-by-background($card-background-color) !important;
          }
        }
      }

      /* Notification */
      .notification-wrapper {
        .vertical-separator:first-of-type {
          display: $notification-vertical-separator-display;
        }
      }

      .notification-dropdown {
        background-color: $card-background-color !important;
        .notification-header {
          background-color: inherit;
        }

        .notification-link-url,
        fgbcl-time-since,
        p,
        .notification-header .text-black,
        .notification-icon {
          color: $text-color !important;
        }

        .notifications-divider {
          opacity: 1;
          border-top: get-value($border, $variables, 'notification', 'border') !important;
        }

        fgbcl-notification-hub {
          .m-2.text-center {
            font-size: 14px;
            margin-bottom: 20px !important;
          }
        }
      }

      /* Marketplace */
      .short-long {
        font-size: 14px;
      }

      #amountInput {
        box-shadow: none;
        color: $text-color;

        &:focus {
          background-color: inherit;
          border: none !important;
        }

        &:disabled {
          color: get-color-by-background($background-color, #dedddd, $black);
        }
      }

      .lotto-item-description {
        color: $black !important;
      }

      .select-attribute-modal-btn {
        font-size: 12px;
        background-color: #f7f7f7;
        border: 1px solid $black;
      }

      .btn-attribute-selected {
        background-color: transparent;
        border: 1px solid $black;
      }

      .loyalty-details {
        .btn:disabled {
          opacity: 0.5 !important;
        }
      }

      .selected-attribute-modal-btn {
        font-size: 12px;
      }

      /* Toast */
      .toast-container .ngx-toastr {
        background-color: $toast-background-color;
        color: get-color-by-background($toast-background-color) !important;
        border-radius: $border-radius !important;

        .notification-link-url,
        .notification-icon,
        .close-btn-toast {
          color: get-color-by-background($toast-background-color) !important;
        }
      }

      /* Surveys */

      .survey-container {
        color: get-color-by-background($survey-quiz-background-color);
        background-color: $survey-quiz-background-color;

        .survey-answer-text-container,
        .survey-message {
          .points-won {
            font-family: $font-heading;
            text-transform: uppercase;
            font-size: 25px;
            line-height: 25px;
            margin-top: 10px;
          }
        }

        /* Trivia */
        &.quiz {
          .survey-header {
            color: get-color-by-background($survey-quiz-header-background-color);

            .no-image {
              background-color: $survey-quiz-header-background-color;
            }
          }

          .survey-info {
            color: get-color-by-background($survey-quiz-info-background-color);
            background-color: $survey-quiz-info-background-color;
          }

          .survey-instructions {
            font-size: $survey-instruction-font-size;
          }

          .survey-answer-item {
            $box-shadow: none;

            @if (lightness($survey-quiz-background-color) < 50%) {
              $box-shadow: none;
            }

            box-shadow: $box-shadow;
            border: 1px solid get-color-by-background($survey-quiz-background-color, $white, $disabled-color);
            border-radius: $border-radius;

            .answer-text {
              color: get-color-by-background($survey-answer-text-background-color);
              background-color: $survey-answer-text-background-color;
            }
          }

          .survey-message.text-danger {
            color: get-color-by-background($survey-quiz-background-color, $white, $error-color) !important;
          }

          .survey-message.text-success {
            color: get-color-by-background($survey-quiz-background-color, $white, $success-color) !important;
          }
        }

        /* Poll */
        &.poll {
          background-color: $survey-poll-background-color;

          .survey-answer-item {
            color: get-color-by-background($survey-poll-background-color);
            border-radius: $border-radius;
            border: 1px solid get-color-by-background($survey-quiz-background-color, $white, $disabled-color);
            width: 90% !important;

            .answer-text {
              color: get-color-by-background($survey-answer-text-background-color);
              background-color: $survey-answer-text-background-color;
            }
          }

          .answer-container {
            .progress {
              color: get-color-by-background($survey-answer-text-background-color);
              background-color: $survey-answer-text-background-color;
            }

            &:not(.selected) {
              .answer-text,
              .answer-text-landscape {
                color: rgba(get-color-by-background($survey-answer-text-background-color), 0.2);
                background-color: rgba($survey-answer-text-background-color, 0.6);
                opacity: 1;
              }

              img {
                opacity: 0.8;
                filter: brightness(0.3);
              }
            }
          }
        }

        /* Predictor */
        &.predictor {
          color: get-color-by-background($survey-predictor-background-color);
          background-color: transparent;

          &.answered {
            gap: 20px;
          }

          .survey-header {
            background-color: $survey-predictor-background-color;
          }

          h3 {
            font-size: 30px;
          }

          .survey-header .image-container {
            z-index: 1;
            background-color: $survey-predictor-header-background-color;
          }

          .survey-answer-item {
            border-radius: $border-radius;
            border: 1px solid get-color-by-background($survey-quiz-background-color, $white, $disabled-color);

            @if (is-light-color($survey-answer-text-background-color) == true) {
              box-shadow: none;
            }

            .answer-text {
              color: get-color-by-background($survey-predictor-answer-text-background-color);
              background-color: $survey-predictor-answer-text-background-color;
            }

            &.selected {
              .answer-text,
              .answer-text-landscape {
                background-color: $survey-predictor-header-background-color;
                color: get-color-by-background($survey-predictor-header-background-color);
              }
            }

            &.portrait {
              .answer-text {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                border-top: 1px solid rgba(0, 0, 0, 0.125);
              }
            }
          }

          .btn-submit {
            font-size: 14px;
            background-color: $accent;
            color: get-color-by-background($accent);
          }

          .countdown-timer {
            font-weight: bold;
          }

          .gIcon-predictor {
            color: $survey-predictor-header-background-color;
          }

          .survey-answer-container {
            &.pending {
              border-radius: $border-radius;
              overflow: hidden;
              color: get-color-by-background($survey-predictor-header-background-color);
              background-color: $survey-predictor-header-background-color;
            }
          }

          .submitted-text {
            color: $survey-predictor-header-background-color;
          }
        }
      }

      .survey-answer-container {
        &.quiz {
          padding: 0 80px;

          @media screen and (max-width: 991px) {
            padding: 0;
          }

          .answer-text {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-size: 12px;
            }
          }
        }
      }

      /* Announcement */
      .announcements-main-title {
        font-size: $header-font-size;
        color: $text-color;
      }

      .announcements-title {
        font-family: $font-default;
        font-size: 14px;
      }

      .announcements-container {
        background-color: $announcement-background-color;
        color: get-color-by-background($announcement-background-color);
      }

      .announcement-link {
        color: get-color-by-background($announcement-background-color);
      }

      /* Profile Page */
      .account-details-card,
      .edit-questions {
        background-color: $card-background-color;

        label {
          color: $text-color;
        }
      }

      .account-details-card {
        .bg-muted {
          background-color: $input-disabled-background-color !important;

          span {
            color: get-color-by-background($input-disabled-background-color) !important;
          }
        }
      }

      .edit-questions,
      .contact-us-box,
      .partner-form {
        select,
        input,
        textarea {
          background-color: $input-background-color !important;
          color: $text-color !important;
          border-color: $input-border-color;
        }
      }

      select:disabled,
      input:disabled,
      textarea:disabled {
        background-color: $input-disabled-background-color !important;
      }

      .contact-us-box {
        color: $text-color;
        background-color: $card-background-color !important;

        textarea,
        select {
          border: 1px solid get-color-by-background($card-background-color, $white, $input-border-color) !important;
        }

        select {
          background: transparent;
          background-image: get-color-by-background($background-color, $arrow-white-color, $arrow-black-color);
          background-size: 14px;
          background-repeat: no-repeat;
          background-position-x: 98%;
          background-position-y: 11px;
          padding: 1rem;
          padding-right: 2rem;
        }

        .contact-us-send-button {
          background-color: get-color-by-background($card-background-color, $primary-color, #231f20) !important;
        }
      }

      .terms-section-box {
        background-color: inherit !important;
        color: $text-color;
      }

      fgbcl-announcement-content {
        h3 {
          font-size: 16px;
        }
      }

      /* Contact Us */

      fgbcl-contact-us {
        .success-message {
          font-size: 12px;
          color: get-color-by-background($background-color, $white, $success-color) !important;
        }
      }

      /* My Representative */
      .representative-info {
        background-color: get-color-by-background($card-background-color, $secondary, $muted-color) !important;
      }

      /* Badges */

      .badge-subheading {
        color: $text-color;
      }

      .purchase-subheading,
      .marketplace-wallet-item-name,
      .marketplace-unclaimed,
      .wallet-item-purchased-value,
      .wallet-item-purchased-subheading,
      .wallet-item-purchased-value,
      .wallet-item-purchased-subheading,
      .wallet-item-purchased-value,
      .lotto-wallet-item-name,
      .wallet-marketplace-item-name,
      .marketplace-unique-code-wallet {
        color: $text-color;
      }

      .redeem-card.border {
        border: get-color-by-background($background-color, $border, 1px solid #dedddd) !important;
      }

      .redeemed-border.border {
        border: $wallet-redeemed-border-bottom-color !important;
      }

      .marketplace-unclaimed,
      .wallet-info-box {
        background-color: $card-background-color !important;
        border-radius: $border-radius;
      }

      .wallet-unique-code-item {
        color: get-color-by-background($card-background-color);
      }

      .wallet-container {
        @if (map-get($variables, 'wallet-redeemed', 'background-color') == null) {
          $wallet-redeemed-background-color: $card-background-color;
        }

        background-color: $wallet-redeemed-background-color;
      }

      .wallet-marketplace-item-name {
        font-size: 12px;
      }

      fgb-wallet-page {
        .claimed-text {
          color: get-color-by-background($background-color, $white, $info-color) !important;
        }
      }

      // CSS hacks
      :has(.wallet-info-box) {
        .wallet-divider {
          display: none;
        }
      }

      @if $theme-name == 'partial-plan' {
        fgbcl-marketplace-purchased-list-item {
          .redeem-card {
            border: none !important;
          }
        }
        fgbcl-marketplace-wallet-details,
        fgbcl-lottos-won-list-item {
          .border {
            border: none !important;
          }
        }
        fgbcl-marketplace-purchased-list-item,
        fgbcl-auction-won-list-item,
        fgbcl-lottos-won-list-item {
          .border {
            border: none !important;
            border-bottom: $wallet-redeemed-border-bottom-color !important;
          }
        }

        .loyalty-item-container-redeemed {
          margin-bottom: 0 !important;
        }

        fgbcl-lottos-wallet-details {
          hr:first-of-type,
          .purchase-subheading:first-of-type,
          .wallet-item-purchased-value:first-of-type {
            display: none !important;
          }
        }

        fgbcl-marketplace-wallet-details {
          .purchase-subheading:first-of-type,
          .wallet-item-purchased-value:first-of-type,
          .d-flex.align-items-center.pb-3:first-of-type {
            display: none !important;
          }
        }
      }

      fgbcl-marketplace-actions,
      fgbcl-lotto-details,
      fgbcl-auction-details {
        .rewards-button-container {
          fgbcl-number-input {
            .form-group.me-lg-3 {
              display: flex;
              justify-content: center;
            }
          }
        }
      }

      fgb-marketplace-purchase-success-page {
        .payment-details {
          background-color: $card-background-color;
          color: get-color-by-background($card-background-color);
          border: get-color-by-background($card-background-color, 1px solid $white, 1px solid $disabled-color) !important;
        }
        .text-success {
          color: get-color-by-background($card-background-color, $white, $success-color) !important;
        }
        .rewards-success-description {
          color: $text-color !important;
        }
      }

      .accordion-button,
      .accordion-button:not(.collapsed) {
        background-color: inherit;
        color: $text-color;
        box-shadow: none;
      }

      .accordion-button.collapsed::after {
        background-image: get-color-by-background($card-background-color, $arrow-white-color, $arrow-black-color) !important;
      }

      .accordion-button[aria-expanded='true']::after {
        background-image: get-color-by-background($card-background-color, $arrow-white-color, $arrow-black-color) !important;
      }

      .accordion-item {
        background-color: inherit;
        color: get-color-by-background($background-color);
      }

      .accordion-body {
        color: $text-color;
      }

      .rewards-button-background {
        background-color: get-color-by-background($background-color, $tertiary, #f7f7f7);
      }

      .badge-container {
        color: get-color-by-background($badge-background-color);
        background-color: $badge-background-color;
      }

      /* Offer */
      .campaign-details {
        color: $text-color;

        .back-button {
          color: $details-back-button-text-color;

          .material-icons {
            font-size: 19px;
          }
        }
      }

      .campaign-name {
        color: get-color-by-background($card-background-color);
      }

      .wallet-back-button,
      .marketplace-wallet-claimed,
      fgb-marketplace-wallet-page .text-danger {
        color: get-color-by-background($card-background-color, $white, $details-back-button-text-color) !important;
      }

      .transaction-history,
      .partners {
        h5 {
          font-size: 18px;
        }
      }

      .transaction-subtext {
        margin-top: 10px;
      }

      /* FAQs */
      fgbcl-faq-section {
        .accordion-item {
          background-color: $card-background-color;
          border-color: get-color-by-background($card-background-color, $secondary, $disabled-color);
        }
      }

      /* Partners */

      .partners,
      .partner-form .icon-cancel-circle,
      .partner-accept-form .icon-cancel-circle {
        color: get-color-by-background($card-background-color);
      }

      .partner-list {
        .delete-btn {
          padding: 0;

          @if is-light-color($card-background-color) == false {
            color: get-color-by-background($card-background-color) !important;
          }
        }
      }

      .accept-partner-modal,
      .delete-partner-modal {
        .modal-header,
        .modal-footer {
          border-color: $modal-border-color !important;
        }
      }

      hr {
        border-color: $modal-border-color;
      }

      .text-info {
        &:not(.marketplace-item-terms, .marketplace-item-description, .partner-instruction) {
          color: get-color-by-background($card-background-color, #ffffff, #999999) !important;
        }
      }

      .partners .text-black {
        color: get-color-by-background($card-background-color, #ffffff, $black) !important;
      }

      .partner-add-form,
      .partner-accept-form {
        $placeholder-color: get-color-by-background($card-background-color, #ffffff, #747272);

        input {
          background-color: $input-background-color !important;
          color: $text-color;

          &::placeholder {
            color: $placeholder-color;
            opacity: 1;
          }

          :-ms-input-placeholder {
            color: $placeholder-color;
          }

          ::-ms-input-placeholder {
            color: $placeholder-color;
          }
        }
      }

      .partner-button {
        background-color: $partner-accept-button-background-color;
      }

      /* Rewards */
      fgbcl-auction-details,
      fgbcl-lotto-details {
        .countdown-timer {
          color: $error-color;
        }
      }

      .mp-event fgbcl-loyalty-icon {
        top: 20% !important;
        left: 20% !important;

        .material-icons {
          font-size: 30px;
        }
      }

      fgbcl-marketplace-details fgbcl-loyalty-icon {
        .material-icons {
          top: 20% !important;
          left: 20% !important;
          font-size: 30px;
        }
      }

      @if ($theme-name == 'wild-wingers') {
        // Hide points for marketplace for kid population
        .marketplace-list-item-points,
        .marketplace-details-points {
          display: none !important;
        }

        #points-value,
        .marketplace-wallet-points-values,
        .wallet-description-divider {
          display: none !important;
        }

        .wallet-item-purchased-subheading.pt-2:nth-of-type(1) {
          border-top: 1px solid rgba(0, 0, 0, 0.125);
          padding-top: 15px !important;

          & + .wallet-item-purchased-value {
            padding-bottom: 8px !important;
          }
        }

        fgb-featured-surveys {
          .owl-theme .owl-dots .owl-dot.active span,
          .owl-theme .owl-dots .owl-dot:hover span {
            background-color: #ffffff;
          }

          .owl-theme .owl-dots .owl-dot span {
            background-color: #111111;
          }
        }
      }

      .btn-marketplace-unredeemable,
      .loyalty-details .unredeemable.btn-grey:disabled,
      .loyalty-details .unredeemable.btn:disabled {
        background-color: $accent !important;
        border-color: $accent !important;
      }

      /* Attributes */

      #attributes-container {
        .modal-header {
          margin-left: 10px;
          margin-right: 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
        }
      }

      #purchase-now-button:disabled {
        background-color: $reward-purchase-button-disabled-background-color !important;
        color: get-color-by-background(
          $reward-purchase-button-disabled-background-color,
          $reward-purchase-button-disabled-text-color,
          $reward-purchase-button-disabled-text-color
        ) !important;
        border-color: $reward-purchase-button-disabled-background-color !important;
        opacity: $reward-purchase-button-disabled-opacity !important;
      }

      fgbcl-marketplace-attributes,
      fgbcl-marketplace-custom-fields {
        .form-floating {
          select,
          input {
            background-color: $reward-attribute-background-color !important;
            color: get-color-by-background($reward-attribute-background-color) !important;
          }
          label {
            color: get-color-by-background($reward-attribute-background-color) !important;
            &::after {
              background-color: inherit !important;
            }
          }
        }

        .form-select {
          // hack using get-color-by-background to change the background image of the select
          $form-select-bg-img: get-color-by-background(
            $reward-attribute-background-color,
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"),
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
          );
          background-image: $form-select-bg-img !important;
        }
      }

      .btn-select-attribute {
        background-color: $reward-attribute-background-color;
        color: get-color-by-background($reward-attribute-background-color);

        label,
        div {
          color: get-color-by-background($reward-attribute-background-color) !important;
        }
      }

      .modal-lg {
        max-width: 620px;
      }

      .form-control:focus {
        border: var(--bs-border-width) solid $disabled-color !important;
      }

      /* Footer */

      @media (max-width: 767px) {
        .banner {
          background-size: 100% 70px !important;
          background-image: $banner-background-mobile;
        }

        .game-nav-wrapper {
          order: $game-nav-mobile-order;
        }
      }

      @media (max-width: 991px) {
        .banner {
          background-size: cover;
        }

        .redeem-card {
          min-height: 259px !important;
        }

        :has(qrcode) {
          .wallet-info-box,
          .marketplace-unclaimed {
            background-color: $white !important;
          }

          .wallet-info-box {
            min-height: 166px;

            qrcode {
              margin-top: 10px;
            }
          }

          .marketplace-unclaimed {
            color: $black !important;
          }
        }
        .survey-container {
          &.quiz {
            .survey-header {
              flex-direction: column;
            }
            .survey-title {
              align-items: center;
              * {
                font-size: 12px;
              }
            }

            .quiz-title {
              font-size: 22px !important;
            }

            .survey-answer {
              .row {
                flex-flow: column-reverse;
              }
            }

            .survey-message.text-danger {
              margin-top: 20px;
            }
          }

          &.predictor {
            .survey-answer {
              margin-top: 10px;
            }
          }
        }

        .partner-list {
          .delete-btn {
            margin-left: 10px;
            margin-top: -5px;
          }
        }

        fgb-transaction-history {
          fgbcl-date-range-picker {
            .to-date-text {
              margin-top: 0 !important;
            }
          }

          .search-button {
            margin-top: 20px !important;
          }
        }
      }

      @content;
    }
  }
}
