@import 'partials';
@import 'mixins';
@import 'club-overrides';

// Import population variables
@import 'fan-variables', 'orange-alliance-variables', 'partial-plan-variables', 'partners-variables', 'wild-wingers-variables';

// Population themes
$orange-alliance: theme('orange-alliance', $black-3, $white, $white, $primary-color);
$fan: theme('fan', $black-2, $black-2, $secondary-dark-color, $primary-color); // default theme
$partial-plan: theme('partial-plan', $black-3, $secondary-dark-color, $black-2, $primary-color);
$partners: theme('partner', $black-3, $white, $white, $primary-color);
$wild-wingers: theme('wild-wingers', $black-3, $white, $white, $primary-color);

// TODO: set default theme, if fan-theme is removed

// Set theme for each population
@include set-theme($fan, $fan-variables) {
  fgb-member-card {
    .member-card {
      min-height: 80px;
    }
  }
}
@include set-theme($orange-alliance, $orange-alliance-variables);
@include set-theme($partial-plan, $partial-plan-variables);
@include set-theme($partners, $partners-variables);
@include set-theme($wild-wingers, $wild-wingers-variables);
