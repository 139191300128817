@import './sass/variables';
@import '@fgb/portal-component-library/styles';
// @import '@fgb/portal-component-library/fonts/icon-font/css/simple-line-icons.min.css';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-toastr/toastr';
@import 'core';

:root {
  --font-default: 'Open Sans', Arial, sans-serif;
  --error-color: #{$error-color};
}

/* ELEMENT STYLING */

h1,
h2,
h3,
h4,
h5 {
  text-transform: capitalize;
}

html {
  background-image: none;
  min-height: 100vh;
  width: 100%;

  body {
    width: 100%;
    font-family: $font-default;
    @if $light-theme {
      background: transparent;
      color: black;
    } @else {
      background: #000;
      color: white;
    }
  }
}

:has(fgb-nav-bar) {
  #main {
    margin-top: $navbar-height;
  }
}

.font-default {
  font-family: $font-default;
}

.font-heading {
  font-family: $font-heading;
}

.font-sans-bold {
  font-family: $font-sans-bold;
}

/* BOOTSTRAP EXTENSIONS */

.cursor-pointer {
  cursor: pointer !important;
}

.btn-circle {
  border-radius: 50%;
}

.banner {
  justify-content: left;
  h1 {
    margin-left: 55px;
    @media (max-width: 991px) {
      margin-left: 0;
    }
  }
}

/* Form Colors */

.form-control {
  color: #000000;
}

/* eCash Styling */

@media (min-width: 1200px) {
  .topUpModal .modal-dialog {
    max-width: 1140px;
  }
}

.svg {
  &.money-bags {
    height: 25px;
    width: 25px;
  }
}

/* Partners */

.partner-add-svg {
  height: 30px;
  width: 30px;
  svg * {
    stroke: transparent;
    fill: white;

    .cls-2 {
      fill: $primary-color;
    }
  }
}

.partner-accept-svg {
  height: 30px;
  width: 30px;
  svg * {
    stroke: $primary-color;
    fill: white;

    .cls-2 {
      fill: $secondary-color;
    }
  }
}

/* CHILDREN OWL CAROUSEL BARCODES */

.children-barcodes {
  .modal-content {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

/* PopUp Notifications */
#action-popup-body {
  .btn:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.importantPopup {
  .modal-content {
    border: 5px solid red;
  }
}

.full-page,
.registration-layout,
.child-upgrade-layout {
  background-image: url('~src/assets/images/login-registration/background.jpg');
}

.registration-layout {
  padding-bottom: 50px !important;
}

.fullScreenPopup {
  .modal-content {
    width: 100vw;
    height: 100vh;
  }
  .modal-dialog {
    position: absolute;
    margin: 0;
  }
}

.toast-container .ngx-toastr {
  display: block;
  width: 370px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  overflow: auto;
  color: #000;
  padding: 0 10px 15px 15px;
}

.announcements-main-title,
.badge-carousel-subheading,
.featured-rewards-heading {
  text-transform: uppercase;
}

fgbcl-badge-overview-list {
  fgbcl-badge-list-item button.mt-3 {
    margin-top: 10px !important;
  }
}

@media screen and (min-width: 992px) {
  .announcements-carousel .owl-item {
    width: 948px !important;
    margin-right: 2px !important;
    margin-left: 3px !important;
  }
}

fgb-fantasy-leaderboard {
  .owl-carousel .owl-item {
    margin-top: 0 !important;
  }

  .leaderboard-nav .owl-theme .owl-nav [class*='owl-prev'],
  .leaderboard-nav .owl-theme .owl-nav [class*='owl-next'] {
    top: -4px;
  }
}

/* Placeholder Styling */

.no-items {
  .no-results-placeholder-icon {
    max-width: 200px;
    margin: 18px auto;

    svg {
      height: 75px;
    }

    &.material-icons-two-tone {
      font-size: 4rem;
      margin: 18px auto 0;
    }
  }

  .no-results-placeholder-title {
    font-size: 1rem;
    font-family: $font-heading;
  }

  .no-results-placeholder-text {
    font-size: 0.75rem;
  }

  &.no-results-placeholder-div {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.125);
    height: 50px;
    @media (max-width: 991px) {
      margin: 0px;
    }

    .no-results-placeholder {
      font-size: 0.75rem;
    }
  }
}

.scorecard-featured-link,
.featured-rewards-text {
  font-size: 12px;
}

.rewards-item-panel-name {
  font-weight: bold;
}

fgbcl-scorecard-list-view,
fgbcl-badge-overview-list {
  .my-5 {
    margin-top: 40px !important;
  }
}

.increment-button,
.decrement-button {
  svg {
    height: 13px;
    width: 13px;
  }
}

.svg-marketplace-details-icons {
  svg {
    height: 14px;
    width: 14px;
  }
}

.redeem-list-item-icons {
  font-size: 18px;
}

.redeem-card {
  min-height: 210px !important;

  @media screen and (max-width: 991px) {
    min-height: 258px !important;
  }
}
/* Errors */

.alert-danger {
  padding: 0 !important;
  margin-top: 4px;
  font-size: 0.625rem;
  background-color: white !important;
  color: $error-color !important;
  border: none !important;
}

.all-offers-steps {
  .card-link-earn-step-box {
    .card-title {
      font-size: 14px;
      color: #c80550;
    }
    .color-1 {
      color: #c80550;
    }
    .color-2 {
      color: #82bee6;
    }
    .color-3 {
      color: #7d4605;
    }
    .card-link-earn-step-icon-border-1 {
      border: 1px solid #c80550;
    }
    .card-link-earn-step-icon-border-2 {
      border: 1px solid #82bee6;
    }
    .card-link-earn-step-icon-border-3 {
      border: 1px solid #7d4605;
    }
  }
}

/* Rewards */

.loyalty .text-on-mp-product {
  color: $white !important;
}

.badge-carousel-subheading {
  font-family: $font-heading;
  color: $white;
  font-size: $header-font-size;
}

.featured-rewards-heading {
  font-size: $header-font-size;
}

.marketplace-item-name,
.auction-detail-name,
.lotto-detail-name {
  font-family: $font-default;
  font-size: 1.5625rem !important;
  font-weight: bold;
}

fgbcl-auction-details {
  .btn-primary {
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;
  }
}

.countdown-timer {
  font-weight: normal;
}

.purchase-confirmation-title,
.auction-modal-title,
.lotto-modal-title {
  font-size: 0.75rem !important;
}

.marketplace-item-description.text-primary,
.marketplace-item-terms.text-primary {
  color: $black !important;
}

.loyalty {
  .bg-disabled {
    background-color: $grey-color !important;
  }
  .text-on-disabled {
    color: $white !important;
  }
}

.featured-rewards-show-all {
  color: $black;
}

fgbcl-notification-hub {
  .increment-page,
  .decrement-page {
    color: $primary-color;
    font-size: 12px;
  }
}

/* Wallet */

.bg-wallet-redeem {
  background-color: $black;
}

/* Scorecards */

#scorecard-progress-bar,
#scorecard-milestone-bar {
  background-color: $secondary-color;
}

.scorecard-description-modal {
  .text-primary {
    color: $black !important;
  }
}

.scorecard,
.scorecard-description-modal {
  .bg-primary {
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;
  }
}

.bg-carousel {
  background-color: $black !important;
}

.scorecard-footer-container {
  background-color: $secondary-color;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
}

.scorecard-name,
#scorecard-name,
.scorecard-locked-name,
#scorecard-modal-name,
.scorecard-description-modal-congratulation-title {
  font-family: $font-default !important;
  font-size: 14px !important;
  text-transform: capitalize;
  font-weight: bold;
}

.scorecard-steps {
  font-family: $font-default;
}

.scorecard-punch-text {
  color: $secondary-color;
  font-size: 0.5rem;
}

.scorecard-punches {
  .bg-primary {
    background-color: $secondary-color !important;
  }
  .border-primary {
    border-color: $secondary-color !important;
  }
}

.badge-featured-list-title,
.wallet-redeemed-title,
.badge-subheading {
  font-size: 1.375rem;
  color: $black;
}

fgbcl-scorecard-carousel {
  .owl-carousel .owl-stage-outer {
    padding-left: 0;
  }
}

.scorecard-milestone-value {
  color: $black;
}

.scorecard-description-modal-prizebox {
  background-color: $secondary-color;
}

.scorecard-description-modal-name {
  font-size: 1rem !important;
}

fgbcl-scorecard-description-modal {
  .scorecard-punch-text {
    font-size: 0.875rem !important;
  }
}

/* Payment */

.payment-cards-title {
  font-size: 1.375rem;
}

fgb-registration-payment {
  #select-country:not(.invalid) {
    background-color: #ffffff !important;
    border: 1px solid #ced4da !important;
  }
  #select-country.invalid,
  #select-region.invalid {
    border: 1px solid $error-color !important;
  }
}

fgbcl-registration-navigation {
  .btn-previous-step {
    border: 0 !important;
  }
}

fgbcl-registration-personal-details {
  @media screen and (min-width: 992px) {
    .model-meta-data-form {
      .col-lg-6 {
        &:nth-child(odd) {
          padding-left: 0 !important;
        }
        &:nth-child(even) {
          padding-right: 0 !important;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .model-meta-data-form {
      .col-lg-6 {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    fgbcl-registration-navigation {
      width: inherit;
      .justify-content-end {
        justify-content: space-between !important;
      }
    }
  }
  .button-container {
    width: 100% !important;
  }
}

.registration-terms-and-condition .content {
  max-height: calc(100vh - 400px);

  @media screen and (max-width: 991px) {
    max-height: unset;
  }
}

.registration-terms-and-condition h5 {
  font-size: 20px;
  text-transform: uppercase;
}

fgbcl-top-up-address {
  .text-danger {
    color: $primary-color !important;
  }
}

.modal-content {
  h6 {
    font-size: 1rem;
    font-family: $font-heading;
  }
}

.add-partner-modal,
.accept-partner-modal {
  .modal-dialog {
    max-width: 634px;
  }
}

.payment-card {
  .btn-status.freeze {
    width: 150px;
    height: 34px;
  }
}

.cash-back-toggle-description {
  font-size: 0.75rem;
}

fgbcl-ecash-description {
  h5 {
    font-size: 1rem;
  }
}

.btn.add-card {
  background-color: $black;
  border-color: $black;
}

.payment-card.inactive-card {
  background-color: #313131;
  p {
    color: $white !important;
  }
}

.payment-card .card-status.inactive {
  background-color: $black;
}

.payment-card .btn-settings.cog .icon {
  color: $white !important;
  margin-top: 10px;
}

#payment-gateway-area {
  text-align-last: center;
}

#cancel-button {
  width: 280px !important;
}

.guardian-modal {
  .modal-dialog {
    max-width: 570px;
  }
}

/* Other */

.nav.nav-secondary {
  margin-bottom: 0;

  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      margin: 0;
    }
  }
}

.rewards-item-panel-value {
  font-size: 18px;
}

@media (min-width: 991px) {
  .nav.nav-secondary .nav-item.active {
    border-bottom: none;
  }
}

fgbcl-terms-and-conditions {
  .terms-section-box {
    box-shadow: none !important;
    padding: 5px !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 0.875rem !important;
    font-family: $font-default !important;
    font-weight: bold !important;
  }

  p {
    font-size: 0.875rem !important;
    font-family: $font-default !important;
    text-indent: 0px !important;
  }
}

fgbcl-faq-section {
  .faq-section-card-title,
  .faq-section-card-description {
    font-family: $font-default !important;
    font-size: 0.875rem !important;
  }

  .faq-container .faq-section-title {
    font-family: $font-heading !important;
  }

  .faq-container .faq-section-title,
  .faq-section-card-title {
    font-weight: bold !important;
    text-transform: uppercase;
  }
}

fgbcl-contact-us {
  .contact-us-dropdown {
    border-right: 1px solid $disabled-color !important;
  }

  .contact-title-text,
  .contact-us-send-button {
    font-family: $font-default !important;
    font-weight: bold !important;
  }

  .contact-title-text {
    font-size: 0.75rem !important;
  }

  .contact-us-send-button {
    background-color: #231f20;
    font-size: 0.875rem !important;
  }
}

.edit-questions .edit:hover,
.edit-questions .edit:focus {
  background-color: $primary-color !important;
}

.terms-section-box {
  border: none;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: $secondary-color;
}

.PopluationId-0 {
  .material-icons-two-tone {
    font-family: 'Material Icons' !important;
    color: $white;
  }
  .no-results-placeholder-text,
  .payment-cards-title,
  .transaction-title {
    color: $white !important;
  }
  .btn.add-card {
    background-color: #313131;
    border-color: #313131;
  }
  .transaction-card {
    box-shadow: none !important;
    border-color: #1f1f1f;
    background-color: #1f1f1f;
  }
  .from-date-text,
  .to-date-text {
    color: $white;
  }
  .date-range-picker .date-field,
  .input-group-append {
    background-color: #313131 !important;
  }
  .date-range-picker .form-control,
  .date-range-picker .btn {
    border: none !important;
    color: $white;
  }
  .input-group-append {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .transaction-arrow {
    color: $white;
  }
  .horizontal-line {
    border-top: 1px solid grey;
  }

  .member-card-title,
  .account-details-title {
    color: $white;
  }
  .terms-section-box,
  .terms-section-content {
    color: $white !important;
  }
  .message-box {
    background-color: #313131;
    color: $white;
  }
  .contact-us-dropdown {
    background-color: #313131;
    color: $white !important;
  }
  .contact-title-text {
    color: $white;
  }
  .faq-container .card {
    background-color: #313131;
    .card-header {
      background-color: #1f1f1f;
    }
  }
  fgbcl-faq-section-card {
    p {
      color: $white;
    }
  }
  .card-link-success-box,
  .card-link-fail-box {
    background-color: #313131;
    color: $white;
  }
  .quiz {
    .answer-text {
      color: $white !important;
    }
  }
  .survey-answer-item {
    box-shadow: none !important;
  }
  .footer {
    background-color: #101010;
  }
  .payment-card .card-status.inactive {
    background-color: #1f1f1f !important;
  }
  .spinner-border {
    color: $white;
  }
  .transaction-placeholder-text {
    color: $white !important;
  }
  .faq-container .card .card-body {
    background-color: transparent;
  }
  .payment-cards-container .add-card-border {
    border: 2px dashed white !important;
  }
  .edit-questions {
    .question-text {
      color: $white;
    }
  }
  .edit-question-title {
    color: $white;
  }
}

.edit-questions .answer-form .appear-appearance {
  height: 40px;
}

.PopluationId-1 {
  .material-icons-two-tone {
    font-family: 'Material Icons' !important;
    color: $grey-color;
  }
  .no-items .no-results-placeholder-text {
    color: $info-color;
  }
  .quiz {
    .image-container {
      box-shadow: 0px 0px 6px #00000029;
    }
  }
}

.no-items .no-results-placeholder-title {
  color: $secondary-color !important;
}

.nav.nav-secondary .nav-item.active label,
.nav.nav-secondary .nav-item.active .icon {
  color: $secondary-color !important;
}

.add-card-label {
  text-transform: capitalize !important;
}

.alert-danger {
  background-color: transparent !important;
}

fgbcl-faq-section-card {
  .card-body {
    min-height: 0;
  }
}

.answer-container .progress-bar {
  background-color: $secondary-color;
}

fgbcl-country-and-regions {
  .form-control {
    background-color: #f7f7f7 !important;
    border: none;
    font-size: 0.875rem;
  }
}

fgbcl-member-purchase-list-item {
  .mb-3 {
    font-size: 0.875rem;
  }
}

fgbcl-top-up-address {
  fgbcl-country-and-regions {
    .form-control {
      background-color: $white !important;
      border: 1px solid #ced4da;
      font-size: 1rem;
    }
  }
  #cancelButton {
    background-color: #e03e3e !important;
    border-color: #e03e3e !important;
  }
}

fgbcl-confirm-modal {
  .btn-cancel {
    background-color: inherit !important;
  }
}

.btn-purchase-cancel {
  background-color: inherit !important;
}

.registration-payment .address-card {
  min-height: 78px !important;
  padding: 15px !important;
  justify-content: flex-start;
}

.registration-payment .registration-payment-content {
  @media screen and (max-width: 991px) {
    padding-bottom: 0 !important;
  }
}

.registration-payment .back-btn {
  margin-top: 0 !important;
}

.registration-payment .action-wrapper {
  margin-top: 20px !important;
}

.registration-payment .registration-payment-address-title,
.registration-payment .summary-card-title {
  line-height: 18px;
  margin-bottom: 0 !important;
}

.registration-payment .summary-title {
  @media screen and (max-width: 991px) {
    padding-top: 10px;
  }
}

.registration-payment .address-card-header {
  margin-bottom: 7px;
}

.registration-payment .form-check-input,
fgb-child-upgrade-checkout .form-check-input {
  border-color: $disabled-color !important;
  cursor: pointer;
}

.form-floating > .form-control:placeholder-shown ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
}

.select-membership-button:hover {
  color: #ffffff !important;
  background-color: #da684a !important;
}

fgb-child-upgrade-checkout {
  fgbcl-errors {
    .alert-danger,
    p {
      margin: 0 !important;
    }
  }

  fgbcl-select-country,
  fgbcl-select-region {
    input.invalid,
    select.invalid {
      border-color: $error-color !important;
    }
  }
}

.date-select .input-wrapper {
  border-radius: $border-radius !important;
}

.registration-payment .summary-card {
  padding: 15px;
}

.shipping-address-form,
.billing-address-form {
  .cancel-btn,
  .cancel-btn:active,
  .cancel-btn:hover {
    background-color: unset !important;
    color: $error-color !important;
    border: none !important;
    font-weight: bold;
  }

  .form-header {
    margin-bottom: 20px;
  }

  .form-header-title {
    font-size: 18px;
    text-transform: uppercase;
  }

  .save-btn {
    font-weight: bold;
  }

  input,
  select {
    font-size: 14px;

    &:focus {
      border: 1px solid var(--bs-border-color);
    }
  }
}

.gift-code-input {
  input:focus {
    border: 1px solid var(--bs-border-color);
  }
  .input-group-append button:disabled {
    background-color: $disabled-color !important;
    border: none !important;
  }
}

.cash-back-toggle-description {
  color: $white;
}

fgbcl-cash-back-toggle {
  .cash-back-toggle-label-on,
  .cash-back-toggle-label-off {
    display: none;
  }
}

.cashback-toggle {
  .cashback-toggle-slider {
    background-color: $disabled-color;

    &::before {
      background-color: $info-color;
      height: 18px !important;
      width: 18px !important;
      top: 1px;
      left: 1px;
    }
  }
}

.btn-purchase-confirm {
  background-color: $success-color !important;
  border-color: $success-color !important;
}

.invalid-feedback {
  font-size: 0.75rem !important;
}

.unique-code-title {
  font-size: 0.75rem;
}

.badge-featured-list-link {
  font-weight: bold;
}

.footer-rights {
  p {
    margin-bottom: 0 !important;
  }
}

.edit-questions {
  .edit {
    background-color: $secondary-color;
    width: 142px;
    margin-top: 10px;
  }
  .cancel,
  .save {
    width: 142px;
    margin-top: 10px;
  }
}

fgb-vouchers,
fgbcl-date-range-picker {
  .input-group-append .btn {
    margin-top: 0 !important;
  }
}

fgb-vouchers {
  .alert-danger,
  .success {
    color: $white !important;
  }
}

fgbcl-v2-scorecard-punchcard-view {
  #scorecard-name {
    padding: 5px 10px;
    line-height: 21px;
    height: 30px;
  }
}

.card-name {
  font-family: $font-heading;
  font-weight: normal;
  text-overflow: ellipsis;
  height: 23px;
  overflow: hidden;
  white-space: nowrap;
}

fgbcl-registration-provider-oauth {
  .tm-login-button {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .login-button {
    background-color: #026cdf !important;

    &:hover {
      color: $white !important;
    }
  }
  .registration-provider-oauth h5 {
    font-size: 20px !important;
    color: $black !important;
    margin-bottom: 10px !important;
  }
  p {
    margin-bottom: 15px !important;
  }
}

fgb-user-login {
  fgb-errors {
    .alert.alert-danger {
      margin-bottom: 0px !important;
    }
  }
}

.announcement-image,
.announcements-text {
  min-height: 200px;
  height: unset !important;
}

/* Registration Partner Invite */

.registration-partner-invite {
  h5 {
    font-size: 20px !important;
    text-transform: uppercase;
    margin-bottom: 10px !important;
  }

  .form-control {
    background-color: $white !important;
    color: $black !important;
  }
}

.registration-personal-details {
  label span {
    display: none;
  }

  h5 {
    font-size: 20px !important;
    text-transform: uppercase;
    margin-bottom: 10px !important;
    text-align: left !important;
  }

  .model-meta-data-form-container {
    height: auto;
  }
}

.registration-add-children {
  .form-group {
    .form-floating {
      input {
        padding-left: 12px !important;
      }
    }
  }
}

fgbcl-registration-membership-options {
  .membership-logo {
    width: 70px;
  }
}

.registration-questions {
  .question-required {
    display: none;
  }

  select {
    font-size: 14px;
  }

  .question-dropdown select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-right: none;
    background-image: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%);
    background-position: calc(100% - 15px) calc(1em + 2px), calc(100% - 10px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;

    &:focus {
      background-image: linear-gradient(45deg, black 50%, transparent 50%), linear-gradient(135deg, transparent 50%, black 50%);
      background-position: calc(100% - 10px) 1em, calc(100% - 15px) 1em, 100% 0;
      background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
      background-repeat: no-repeat;
      border-color: grey;
      outline: 0;
    }
  }
}

.registration-add-children,
.registration-membership-options {
  .logo {
    height: 100px;
  }
}

.child-membership-option {
  select {
    height: 36px;
    padding: 6px 8px !important;
  }
}

.contact-us-dropdown-div {
  margin-bottom: 20px !important;
}

.payment-logo {
  height: 100px;
}

.registration-panel {
  .d-lg-none {
    margin-bottom: 0 !important;
  }
}

.registration-payment-modal {
  .registration-payment-modal-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
    background-color: $quaternary-color;
    color: #ffffff;

    .registration-payment-modal-title {
      margin-left: 10px;
      margin-bottom: 0;
      font-size: 18px;
    }

    .close-btn {
      cursor: pointer;
      margin-left: auto;
      font-family: 'Material Icons Outlined';
    }
  }

  .registration-payment-modal-info,
  .payment-info {
    display: flex;
    justify-content: center;
    margin: 30px 0px;

    p {
      display: flex;
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .registration-payment-modal-info {
    p {
      max-width: 400px;
    }
  }

  .payment-info {
    p {
      max-width: 310px;
    }

    .total-label {
      font-weight: 100;
    }

    .total-amount {
      margin-left: auto;
    }
  }

  .payment-container {
    display: flex;
    justify-content: center;
  }

  .payment-form {
    max-width: 310px;
  }
}

.registration-payment-modal .registration-payment-add-payment-modal-header .registration-payment-modal-title {
  font-family: $font-default !important;
  font-weight: bold;
}

.rewards-confirm-divider.mb-lg-5 {
  margin-bottom: 20px !important;
}

// CSS hacks

:has(fgbcl-marketplace-custom-fields) {
  .form-check {
    margin-top: 0 !important;
  }

  fgbcl-marketplace-custom-fields {
    form .row.mb-lg-2 {
      margin-bottom: 0 !important;
    }
  }
}

.registration-personal-details .model-meta-data-form-container {
  overflow-y: unset;
}

:has(#purchase-now-button:disabled) {
  #amountInput {
    pointer-events: none;
    opacity: 0.6;
  }
}

fgbcl-registration-partner-invite {
  .form-floating {
    label {
      display: flex;
      align-items: center;
    }
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.form-check-input:checked {
  background-color: $success-color !important;
}
#accepted.form-check-input:checked,
#checkbox.form-check-input:checked {
  border: 1px solid $success-color !important;
}

textarea,
input,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

.date-select:has(input:focus) .input-wrapper,
input:focus,
textarea:focus,
select:focus,
input:active,
textarea:active,
select:active {
  box-shadow: none !important;
}

.confirmation-custom-field-value {
  word-break: break-word;
}

.auction-details-countdown,
.lotto-details-countdown {
  margin-bottom: 5px;
}

.loyalty-details {
  .card-body {
    padding-top: 5px !important;
  }
}

.marketplace-attribute .form-attribute .form-select:focus,
.marketplace-attribute .form-attribute .form-select:active {
  border-color: var(--bs-border-color);
}

@media screen and (min-width: 991px) {
  fgbcl-marketplace-actions,
  fgbcl-auction-details,
  fgbcl-lotto-details {
    .rewards-button-container {
      .quantity-select-container {
        width: 172px;
      }
      div.align-items-center {
        margin-left: 10px !important;
        margin-right: 70px !important;
      }
      fgbcl-number-input {
        .form-group.me-lg-3 {
          margin-right: 0 !important;
        }
      }
    }
  }
}

.notification-item.card {
  .col-10 {
    padding-left: 20px !important;
  }
}

.rewards-terms-label.mt-1 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.registration-membership-option-title,
.registration-add-children-title,
.registration-payment-address-title,
.summary-card-title,
.registration-payment-billing-form .form-header-title,
.registration-payment .form-header-title,
.membership-detail h5 {
  text-transform: uppercase !important;
  color: $black !important;
  font-size: 18px !important;
}
.form-header-title {
  margin-bottom: 0 !important;
}

.child-title,
.child-membership-item h5 {
  text-transform: uppercase !important;
  font-size: 18px !important;
}

.registration-payment-address .material-icons {
  color: $black !important;
}

.summary-title {
  font-size: 14px !important;
  font-family: $font-default !important;
  font-weight: bold;
}

@for $i from 0 through 10 {
  #customField_#{$i}:focus {
    box-shadow: 0 0 0px 1000px $white inset !important;
    -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
  }
}

@media screen and (max-width: 991px) {
  .registration-partner-invite-instruction {
    font-size: 14px !important;
  }

  .registration-payment-shipping-form .action-wrapper,
  .registration-payment-billing-form .action-wrapper {
    flex-direction: row !important;
  }

  fgbcl-registration-add-children {
    .col-lg-12 {
      padding: 0 !important;
    }
  }

  .registration-add-children .model-meta-data-form {
    flex-direction: column;
  }

  fgbcl-announcements,
  .announcements-carousel {
    .owl-item {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  .notification-dropdown {
    height: fit-content;

    p.text-center {
      margin-bottom: 30px !important;
    }
  }

  .registration-provider-oauth {
    h5 {
      text-align: left !important;
    }
  }

  .registration-add-children-title {
    font-size: 24px !important;
  }

  .registration-add-children-instructions {
    font-size: 12px !important;
  }

  .registration-membership-option-title {
    margin-bottom: 20px;
    font-size: 24px !important;
  }

  .registration-membership-options {
    img.logo {
      margin-bottom: 0 !important;
    }
  }

  .registration-payment-title {
    font-size: 24px !important;
  }

  .registration-success-page-title {
    text-align: center;
  }

  .registration-content {
    .back-btn {
      max-width: 100%;
    }
  }

  .registration-add-children-title {
    margin-top: 0 !important;
  }

  .children-form-container {
    overflow: visible !important;
  }

  .reward-confirm-modal {
    .modal-footer {
      display: flex;
      justify-content: space-evenly !important;
    }
  }
}

/* Loading CL component */
fgbcl-loading {
  .spinner-border {
    .sr-only {
      display: none;
    }
  }
}

@import 'bootstrap/scss/bootstrap';

/* ON COLOURS */

@each $color, $value in $theme-colors {
  .text-on-#{$color} {
    color: color-contrast($value, $dark, $light) !important;
  }

  .svg-#{$color} {
    color: $value;
    .cls-1 {
      fill: $value !important;
      stroke: $value !important;
    }
    .circle {
      fill: $value !important;
      stroke: $value !important;
    }
  }
}
