.app-loading {
  .logo {
    width: 100px;
    height: 100px;
    background: url(./assets/images/logos/Ducks.svg) center center no-repeat;
    animation: zoom-in-zoom-out 1s ease infinite;
    -webkit-animation: zoom-in-zoom-out 1s ease infinite;

    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.5, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
    }
    @-webkit-keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.5, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }
}
